<template>
  <div>
    <data-iterator :headers="payloadHeaders" :payload="payload" class="ma-2"></data-iterator>
  </div>
</template>
<script>
export default {
  data () {
    return {
      roleId:  null,
      userRoleObj: null,
      payload: {
        showBackBtn: () => this.$router.go(-1),
        addNew: () => this.$router.push(`/users_roles/${this.$route.params.role_id}/users/actions`),
        create: () => this.$router.push(`/users_roles/${this.$route.params.role_id}/users/actions`),
        items: [],
        showSelect: false,
        addClickHandler: (item) => this.viewHandler(item._id),
        actionsList: [{
          click: (item) => this.viewHandler(item._id),
          icon: 'mdi-eye',
          color: 'primary'
        }, {
          click: (item) => this.editHandler(item._id),
          icon: 'mdi-pencil',
          color: 'primary'
        }, {
          click: (item) => this.deleteHandler(item._id),
          icon: 'mdi-delete',
          color: 'error'
        }]
      }
    }
  },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  mounted () {
    this.roleId = this.$route.params.role_id
    this.getUserRole()
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Name',
        value: 'formdata.name'
      }, {
        text: 'Email',
        value: 'formdata.email'
      }, {
        text: 'Profile',
        value: 'profile'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  methods: {
    getUserRole () {
      this.$_execute('get', `user_roles/${this.roleId}`).then(({ data }) => {
        this.userRoleObj = data
        this.getAllUsers()
      })
    },
    getAllUsers () {
      let query = [
        {
          $set: { role: { $toString: "$role" } },
        },
        {
          $match: {
            $and: [{ role: { $eq: this.roleId } }],
          },
        },
      ]
      this.$_execute('post', 'users/query', query).then(({ data }) => {
        this.payload.items = data
      })
    },
    viewHandler(id) {
      this.$router.push(`/users_roles/${this.$route.params.role_id}/users/view/${id}`)
    },
    editHandler (id) {
      this.$router.push(`/users_roles/${this.$route.params.role_id}/users/actions/${id}`)
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'users',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getAllUsers()
        }
      })
    }
  }
}
</script>
